import React from 'react';
import { Link } from 'gatsby';
import { Card } from 'antd';

const AboutMe = props => {
  return (
    <Card 
      style={{
        marginBottom: 10
      }}>
        <Link className="button" to={`/about-me`}>
          About Me
        </Link>
    </Card>
  )
}

export default AboutMe;
import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import { Card, Col, Row } from 'antd';

class BlogRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <Row gutter={24}>
        {posts &&
          posts.map(({ node: post }, index) => (
            <Col key={index} span={24}>
              <Card 
                style={{
                  marginBottom: 20
                }}
                title={
                  <Link className="button" to={post.fields.slug}>
                    {post.frontmatter.title}
                  </Link>
                }>
                  <Row gutter={24}>
                    <Col lg={6} md={6} sm={24} xs={24}>
                      <Link to={post.fields.slug}>
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: post.frontmatter.featuredimage,
                            alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                          }}
                        />
                      </Link>
                    </Col>
                    <Col lg={18} md={18} sm={24} xs={24}>
                      <p>Date: {post.frontmatter.date}</p>
                      <p>
                        {post.frontmatter.description}
                      </p>
                    </Col>
                  </Row>
              </Card>
            </Col>
          ))}
      </Row>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 200)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                description
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 360, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)

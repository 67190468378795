import React from 'react';
import { Card } from 'antd';
import { Link } from 'gatsby';
import { kebabCase } from 'lodash';
import { Tag } from 'antd';

const Tags = props => {
  const { tags } = props;

  return (
    <Card 
      style={{
        marginBottom: 40
      }}
      title={
        <Link className="button" to={`/tags`}>
          Popular Topics
        </Link>
      }>
        {tags.map(tag => (
          <Tag color="#23c3bd" key={`tag-key-${kebabCase(tag.fieldValue)}`}>
            <Link className="button" to={`/tags/${kebabCase(tag.fieldValue)}`}>
              {tag.fieldValue}
            </Link>
          </Tag>
        ))}
    </Card>
  )
}

export default Tags;
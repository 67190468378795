import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from './../components/Layout'
import BlogRoll from './../components/BlogRoll'
import { Card, Col, Row } from 'antd';
import AboutMe from './../components/AboutMe';
import Tags from './../components/Tags';

export const IndexPageTemplate = ({
  title,
  tags
}) => (
  <Row gutter={24}>
    <Col 
      xxl={{
        span: 18,
        offset: 3
      }}
      xl={{
        span: 18,
        offset: 3
      }}
      lg={{
        span: 24,
        offset: 0
      }}
      md={{
        span: 24,
        offset: 0
      }}
      sm={{
        span: 24,
        offset: 0
      }}
      xs={{
        span: 24,
        offset: 0
      }}
    >
      <Row gutter={24}>
        <Col span={18} sm={24} md={18} lg={18} xs={24}>
          <BlogRoll />
        </Col>
        <Col span={6} sm={24} md={6} lg={6} xs={24}>
          <AboutMe />
          <Tags tags={tags} />
        </Col>
      </Row>
    </Col>
  </Row>
)

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  tags: PropTypes.array
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const tags = data.allMarkdownRemark.group;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        description={frontmatter.description}
        intro={frontmatter.intro}
        tags={tags}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.array
    })
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        description
      }
    }
    allMarkdownRemark(limit: 50) {
      group(field: frontmatter___tags) {
        fieldValue
      }
    }
  }
`
